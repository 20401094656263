import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Column from '@amzn/meridian/column';
import Toggle from '@amzn/meridian/toggle';

import { setListORamaPassThroughFlag } from '../../../config/axiosConfig';

export const OSKAR_PASS_THROUGH_PARAM = 'passThrough';

interface OskarPassThroughToggleProps {
    state: boolean;
}

export const OskarPassThroughToggle: React.FC<OskarPassThroughToggleProps> = (props: OskarPassThroughToggleProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState<boolean>(props.state);

    // Store toggle state into browser session state so we can recover pass-through mode query param if it is not
    // present (such as during page navigation).
    setListORamaPassThroughFlag(props.state);

    const togglePassThrough = (newState: boolean): void => {
        searchParams.set(OSKAR_PASS_THROUGH_PARAM, newState.toString());
        setSearchParams(searchParams);

        // This state is for unit testing purposes and doesn't actually matter,
        // since the page will be refreshed anyway.
        setState(newState);

        // reload the page to re-fetch data from ListORama
        window.location.reload();
    };

    return (
        <Column>
            <Toggle checked={state} onChange={(newState: boolean) => togglePassThrough(newState)}>
                Oskar Pass-through Mode
            </Toggle>
        </Column>
    );
};
