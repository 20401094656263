import { getAppSetting } from '../config/AppSettings';

export const getListWebsiteUrl = (listId: string) => {
    switch (getAppSetting('stage')) {
        case 'Alpha':
        case 'Beta':
            return `https://dev.imdb.com/list/${listId}`;
        default:
            return `https://imdb.com/list/${listId}`;
    }
};

export const getUserAdminUrlForAuthor = (authorId: string) => {
    switch (getAppSetting('stage')) {
        case 'Alpha':
        case 'Beta':
            return `https://imdb-useradmin-beta.corp.amazon.com/?customerid=${authorId}`;
        default:
            return `https://imdb-useradmin-iad.iad.proxy.amazon.com/?customerid=${authorId}`;
    }
};
